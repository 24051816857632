.App-logo {
  position: absolute;
  left: 0;
  top: 0;
}
.App {
  background-color: #f1f1f1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: calc(8px + 2vmin);
  color: black;
  height: 100%;
  z-index: -101;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.App-header {
  padding: 6px;
  top: 0;
  left: 0;
  width: 100%;
  display: table;
  vertical-align: middle;
}
